import React, { useState, useReducer, useEffect } from "react"
import "./contact_page.css"
import contactBlob from "../../images/ContactUsBlob.svg"
import newMsg from "../../images/NewMessage.svg"

function handleError(state, action) {
  switch (action.type) {
    case "full-name":
      return {
        ...state,
        fullName: action.state ? "initial" : "none",
      }
    case "email":
      return {
        ...state,
        email: action.state ? "initial" : "none",
      }
    case "message":
      return {
        ...state,
        message: action.state ? "initial" : "none",
      }
    default:
      return state
  }
}

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactPage = () => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [attempted, setAttempted] = useState(false)
  const [error, dispatch] = useReducer(handleError, {
    fullName: "none",
    email: "none",
    message: "none",
  })

  function handleDisplayErr() {
    if (attempted) {
      if (fullName !== "" && validateEmail(email) && message !== "") {
        setAttempted(false)
      } else {
        if (fullName === "") {
          dispatch({ type: "full-name", state: true })
        } else {
          dispatch({ type: "full-name", state: false })
        }

        if (!validateEmail(email)) {
          dispatch({ type: "email", state: true })
        } else {
          dispatch({ type: "email", state: false })
        }

        if (message === "") {
          dispatch({ type: "message", state: true })
        } else {
          dispatch({ type: "message", state: false })
        }
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (fullName !== "" && validateEmail(email) && message !== "") {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          [`full-name`]: fullName,
          email,
          message,
        }),
      })
        .then(() => alert("Success!"))
        .catch(error => alert(error))
    } else {
      setAttempted(true)
    }
  }

  useEffect(() => {
    if (attempted) {
      handleDisplayErr()
    }
  }, [attempted])

  return (
    <div className="contact-page" id="contact-page">
      <img className="blob" src={contactBlob} alt="decoration" />

      <section>
        <div className="image-wrapper">
          <img src={newMsg} className="contact-image" alt="contact-us" />
        </div>

        <div className="contact-form-wrapper">
          <h1 className="heading">Get in touch</h1>

          <form
            name="contact"
            method="POST"
            netlify
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <label>
              <div className="error-wrapper">
                <p className="input-heading">FULL NAME</p>
                <p
                  id="full-name-error"
                  className="error"
                  style={{ display: error.fullName }}
                >
                  Your name can't be blank!
                </p>
              </div>
              <input
                id="full-name"
                name="full-name"
                type="text"
                className="form-inputs"
                placeholder="John Doe"
                htmlFor="name"
                value={fullName}
                onChange={e => {
                  handleDisplayErr()
                  setFullName(e.target.value)
                }}
              />
            </label>

            <label>
              <div className="error-wrapper">
                <p className="input-heading">EMAIL</p>
                <p
                  id="email-error"
                  className="error"
                  style={{ display: error.email }}
                >
                  Please use a proper email!
                </p>
              </div>
              <input
                id="email"
                name="email"
                type="email"
                className="form-inputs"
                placeholder="john.doe@email.com"
                htmlFor="email"
                value={email}
                onChange={e => {
                  handleDisplayErr()
                  setEmail(e.target.value)
                }}
              />
            </label>

            <label>
              <div className="error-wrapper">
                <p className="input-heading">MESSAGE</p>{" "}
                <p
                  id="message-error"
                  className="error"
                  style={{ display: error.message }}
                >
                  The message can't be blank!
                </p>
              </div>
              <textarea
                id="message"
                name="message"
                type="text"
                className="form-inputs"
                rows="8"
                value={message}
                onChange={e => {
                  handleDisplayErr()
                  setMessage(e.target.value)
                }}
              />
            </label>

            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />

            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>
      </section>
    </div>
  )
}

export default ContactPage
